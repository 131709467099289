import React from 'react'
import { Link } from 'react-router-dom'
import './ContactsHead.css'
import logo from '../../../Media/logo.svg'
import Navigation from '../../Common/Navigation/Navigation'
import map from '../../../Media/MapDesktop.svg'

const ContactsHead = ({ isMobile, qrCode, backendUrl }) => {

  return (
    <section className='contactsHead container'>
        <div className='contactsHead__wrapper'>
            <div className='contactsHead__banner'>
                <div className='contactsHead__banner-top'>
                    <Link to='/'><img src={logo} className='contactsHead__logo' alt='logo'/></Link>
                    <Navigation />
                </div>
                <div className='contactsHead__banner-bottom'>
                    <div className='contactsHead__banner-titles'>
                        <h1>Контакты</h1>
                        <p>Мы всегда на связи и готовы обсудить ваш проект</p>
                    </div>
                    <div className='contactsHead__banner-cutaway'>
                        {
                            isMobile
                            ? 
                                <>
                                    <Link to='/visitingcard' className='contactsHead__banner-qr'></Link>
                                    <p>Наша визитка</p>
                                </>
                            : 
                                <>
                                    {qrCode && <img src={`${backendUrl}${qrCode.url}`} alt={qrCode.alternativeText} className='contactsHead__banner-qr'></img>}
                                    <p>Скачать визитку</p>
                                </>
                        }
                    </div>
                </div>
            </div>
            <div className='contactsHead__map'><img src={map} alt='Карта' /></div>
        </div>
    </section>
  )
}

export default ContactsHead