import React from 'react'
import './ContactsWriteUs.css'
import FeedbackForm from '../../Common/FeedbackForm/FeedbackForm'

const ContactsWriteUs = ({ data }) => {
  return (
    <section className='contactsWriteUs container'>
        <div className='contactsWriteUs__wrapper'>
            <div className='contactsWriteUs__info'>
                <div className='contactsWriteUs__title'>
                    <h2>Есть вопросы?</h2>
                    <p>Получите предварительную калькуляцию вашего проекта в течение 12 часов:</p>
                </div>
                <div className='contactsWriteUs__data'>
                    {
                        data && 
                        <>
                            <a href={`tel: ${data.Phone}`} className='contactsWriteUs__phone'>{data.Phone}</a>
                            <a href={`mailto: ${data.Email}`} className='contactsWriteUs__email'>{data.Email}</a>
                        </>
                    }

                </div>
            </div>
            <FeedbackForm />
        </div>
    </section>
  )
}

export default ContactsWriteUs