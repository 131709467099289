import React, { useEffect, useState } from 'react'
import { NavLink, Link } from 'react-router-dom'
import './HeaderMobile.css'
import logo from '../../../Media/logo.svg'

const HeaderMobile = ({ isMobile }) => {

  const [isMobileMenuActive, setIsMobileMenuActive] = useState(false)

//   useEffect(() => {
//     if(!isMobile) {
//         document.body.style.overflow = 'visible'
//     }
//   }, [isMobile])

//   useEffect(() => {
//     document.body.style.overflow = `${isMobileMenuActive ? 'hidden' : ''}`
//     console.log(isMobile)
//     console.log(isMobileMenuActive)
//   }, [isMobileMenuActive, isMobile])

  useEffect(() => {
    document.body.style.overflow = `${isMobileMenuActive ? 'hidden' : ''}`
  }, [isMobileMenuActive])


  return (
    <>
        <header className='header-mobile'>
            <div className='container'>    
                <div className={`header-mobile__content`}>
                    <div className='header-mobile__head'>
                        <Link to='/' className='header__logo-link'><img src={logo} className='header__logo' alt='logo'/></Link>
                        <div className={`header-mobile__button ${isMobileMenuActive ? '_active' : ''}`} onClick={()=>setIsMobileMenuActive(prevState => !prevState)}></div>    
                    </div>
                </div>
            </div>
        </header>
        <div className={`mobile-menu__wrapper ${isMobileMenuActive ? 'mobile-nav-active' : ''}`}>
            <div className='mobile-menu__navigation'>
                <nav>
                    <ul className='mobile-menu__nav-list'>
                        <li className='mobile-menu__nav-list-item'><NavLink to='/' onClick={() => setIsMobileMenuActive(false)}>Главная</NavLink></li>
                        <li className='mobile-menu__nav-list-item'><NavLink to='/contacts' onClick={() => setIsMobileMenuActive(false)}>Контакты</NavLink></li>
                    </ul>
                </nav>
                <nav className='mobile-menu__service'>
                    <label>Наши услуги</label>
                    <ul className='mobile-menu__service-list'>
                        <li className='mobile-menu__service-list-item'>
                            <NavLink to='/interactive' onClick={() => setIsMobileMenuActive(false)}>
                                <div className='nav-icon nav-icon-interactive'></div>
                                Interactive
                            </NavLink>
                        </li>
                        <li className='mobile-menu__service-list-item'>
                            <NavLink to='/business' onClick={() => setIsMobileMenuActive(false)}>
                                <div className='nav-icon nav-icon-business'></div>
                                Business
                            </NavLink>
                        </li>
                        <li className='mobile-menu__service-list-item'>
                            <NavLink to='/online' onClick={() => setIsMobileMenuActive(false)}>
                                <div className='nav-icon nav-icon-online'></div>
                                Online
                            </NavLink>
                        </li>
                    </ul>
                </nav>
            </div>
            <div className='mobile-menu__bottom'>
                <div className='mobile-menu__bottom-links'>
                    <a href='#' className='mobile-menu__bottom-link'>Наша презентация</a>
                    <a href='#' className='mobile-menu__bottom-link' style={{alignSelf: 'flex-end'}}>AppStore</a>
                    <a href='#' className='mobile-menu__bottom-link'>Google Play</a>
                </div>
                <div className='mobile-menu__soc-network'>
                    {/* <a href='#' className='mobile-menu__soc-netw telegram'></a>
                    <a href='#' className='mobile-menu__soc-netw youtube'></a> */}
                </div>
            </div>
        </div>
    </>
  )
}


export default HeaderMobile