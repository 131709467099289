import React, { useState, useEffect } from 'react'
import CompetenceHead from '../Common/СompetenceHead/СompetenceHead'
import SolutionsList from '../Common/SolutionsList/SolutionsList'
import Strengths from '../MainPage/Strengths/Strengths'
// import clientReviewsData from '../../TestData/clientReviewsData.js'
import api from '../../Utils/Api'

const Business = ({ isMobile }) => {

  const [reviews, setReviews] = useState(undefined)
  const [headerData, setHeaderData] = useState(undefined)

  useEffect (() => {
    Promise.all(
      [api.getBusinessPage()])
      .then(([data]) => {
        setReviews(data.data.attributes.Reviews)
        setHeaderData(data.data.attributes.Competence.competence.data.attributes)
      }
    )
    .catch((e) => console.error(e));
  }, [])

  return (
    <>
        <CompetenceHead
            color={'#5FFFD8'}
            data={headerData}
            backendUrl={api._address}
        />
        <SolutionsList />
        <Strengths
          stData={reviews}
          isReview={true}
          title='Отзывы клиентов'
          description='К сожалению, мы не можем показать здесь кейсы, так как многие из них находятся под Договором о неразглашении. Но отзывы наших клиентов говорят больше, чем любые визуальные представления проектов.'
          backendUrl={api._address}
        />
    </>
  )
}

export default Business