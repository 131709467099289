import React from 'react'
import CaseCard from '../CaseCard/CaseCard'
import './ReadyMechanics.css'

const ReadyMechanics = ({ readyMechanicsData, backendUrl }) => {

  return (
    <section className='readyMechanics container'>
      <div className='readyMechanics__wrapper'>
        <h2 className='readyMechanics__title'>Готовые механики,</h2>
        <p className='readyMechanics__caption'>которые мы можем адаптировать для ваших задач</p>
        <div className='readyMechanics__list'>
          {
            readyMechanicsData?.map((item, i) => 
              <div className='readyMechanics__list-item' key={i}>
                <CaseCard bgColor={'#fff'} type='readyMechanics' cardData={item} backendUrl={backendUrl} />
              </div>
            )
          }
        </div>
      </div>
    </section>
  )
}

export default ReadyMechanics