import React, { useEffect, useState } from 'react'
import './FeedbackForm.css'
import api from '../../../Utils/Api'

const FeedbackForm = () => {

  const [userName, setUserName] = useState('')
  const [userEmail, setUserEmail] = useState('')
  const [userPhone, setUserPhone] = useState('')
  const [userMessage, setUserMessage] = useState('')
  const [userData, setUserData] = useState({})
  const [nameIsDirty, setNameIsDirty] = useState(false)
  const [emailIsDirty, setEmailIsDirty] = useState(false)
  const [phoneIsDirty, setPhoneIsDirty] = useState(false)
  const [messageIsDirty, setMessageIsDirty] = useState(false)
  const [nameError, setNameError] = useState('Необходимо заполнить поле')
  const [emailError, setEmailError] = useState('Необходимо заполнить поле')
  const [phoneError, setPhoneError] = useState('')
  const [messageError, setMessageError] = useState('Необходимо заполнить поле')

  const [isFormValid, setIsFormValid] = useState(false)
  const [isFeedbackSent, setIsFeedbackSent] = useState(false)

  useEffect(() => {
    setUserData({ name: userName, email: userEmail, phone: userPhone, message: userMessage })
  }, [userName, userEmail, userPhone, userMessage])


  useEffect( () => {  //проверка на валидность формы
    if(nameError || emailError || phoneError || messageError) {
        setIsFormValid(false)
    } else {
        setIsFormValid(true)
    }
  }, [nameError, emailError, phoneError, messageError])

  const handleEmail = (e) => {
    setUserEmail(e.target.value);

    const emailReg = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

    if(!emailReg.test(String(e.target.value).toLowerCase())) {
        setEmailError('Некорректный адрес электронной почты');
        if(!e.target.value)
          setEmailError('Необходимо заполнить поле');
    } else {
      setEmailError('');
    }
  };

  const handleName = (e) => {
    setUserName(e.target.value)

    if(!e.target.value) {
      setNameError('Необходимо заполнить поле')
    } else {
      setNameError('')
    }
  }

  const handlePhone = (e) => {
    setUserPhone(e.target.value)

    const phoneReg = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/;
    if(!phoneReg.test(String(e.target.value).toLowerCase())) {
      setPhoneError('Некорректный формат телефона');
      if(!e.target.value)
        setPhoneError('');
    } else {
      setPhoneError('');
    }
  }

  const handleMessage = (e) => {
    setUserMessage(e.target.value)
    if(!e.target.value) {
      setMessageError('Необходимо заполнить поле')
    } else {
      setMessageError('')
    }
  }

  const blurHandler = (e) => {    //проверка были ли мы в инпуте
    switch(e.target.name) {
        case 'name':
          setNameIsDirty(true);
          break;
        case 'email':
          setEmailIsDirty(true);
          break;
        case 'tel':
          setPhoneIsDirty(true);
          break;
        case 'message':
          setMessageIsDirty(true);
          break;
        default:
          break;
    }
  };

  const feedbackHandler = (data) => {
    api.sendFeedback(data)
    .then((res) => {
      console.log('your feedback sent succesfully')
    })
    .catch((e) => {
      console.error(e);
      console.log('something went wrong')
    });
    setIsFeedbackSent(true)
  }

  const clearForm = () => {
    setUserEmail('')
    setUserPhone('')
    setUserName('')
    setUserMessage('')
    setNameIsDirty(false)
    setEmailIsDirty(false)
    setPhoneIsDirty(false)
    setMessageIsDirty(false)
    setNameError('Некорректный формат телефона')
    setEmailError('Некорректный формат телефона')
    setPhoneError('')
    setMessageError('Некорректный формат телефона')
    setIsFormValid(false)
  }


  return (
    <div className='feedbackForm'>
      {!isFeedbackSent ?
      <>
        <div className='feedbackForm__heading'>
            <h3 className='feedbackForm__heading-title'>Тема</h3>
            <span className='feedbackForm__heading-request'>Запрос</span>
        </div>
        <form>
            { (nameIsDirty && nameError) && <span className='error-message name'>{nameError}</span> }

            <input type='text' placeholder='Имя' name='name' value={userName} required
            className={`feedbackForm__input ${(nameIsDirty && nameError) ? 'input-error' : ''}`}
            onChange={(e) => handleName(e)}
            onBlur={(e) => blurHandler(e)} ></input>
            <span className='feedbackForm__required' style={{top: 24}}>*</span>


            { (emailIsDirty && emailError) && <span className='error-message email'>{emailError}</span> }

            <input type='email' placeholder='Email' name='email' value={userEmail} required
            className={`feedbackForm__input ${(emailIsDirty && emailError) ? 'input-error' : ''}`}
            onChange={(e) => handleEmail(e)}
            onBlur={(e) => blurHandler(e)}></input>
            <span className='feedbackForm__required' style={{top: 90}}>*</span>


            { (phoneIsDirty && phoneError) && <span className='error-message phone'>{phoneError}</span> }

            <input type='tel' placeholder='Телефон' name='tel' value={userPhone} 
            className={`feedbackForm__input ${(phoneIsDirty && phoneError) ? 'input-error' : ''}`}
            onChange={(e) => handlePhone(e)}
            onBlur={blurHandler} ></input>


            { (messageIsDirty && messageError) && <span className='error-message message'>{messageError}</span> }

            <div className='feedbackForm__textariablock'>
              <textarea type='text' placeholder='Ваш запрос' name='message' value={userMessage} required
              className={`feedbackForm__input textaria ${(messageIsDirty && messageError) ? 'input-error' : ''}`}
              onChange={(e) => handleMessage(e)}
              onBlur={(e) => blurHandler(e)} ></textarea>
            </div>
            <span className='feedbackForm__required' style={{top: 222}}>*</span>
        </form>

        <div className='feedbackForm__bottom'>
          <p className='feedbackForm__rules'>
            Нажимая на кнопку отправки сообщения, Вы соглашаетесь с <a href='#'>Правилами обработки данных</a> и <a href='#'>Политикой конфиденциальности</a>
          </p>
          <div className={`feedbackForm__button ${!isFormValid ? 'fb-disabled' : ''}`}
            onClick={isFormValid ? () => {feedbackHandler(userData); clearForm()} : () => {}}>
          </div>
        </div>
      </> :
      <div className='feedback__sent'>
        <h2>Спасибо</h2>
        <p>Мы ответим Вам в течение 24 часов</p>
        <div className={`feedbackForm__button`} onClick={() => setIsFeedbackSent(false)}>
        </div>
      </div>
      }
    </div>
  )
}

export default FeedbackForm