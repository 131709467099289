import React from 'react'
import './CaseList.css'
import CaseCard from '../CaseCard/CaseCard'

const CaseList = ({ isMobile, caseData, backendUrl }) => {

  return (
    <section className='caseList container'>
        {
            //возможно лучше заменить условние в article на css свойство для потомков caseList (even, odd)
            caseData?.map((item, index) => 
                <article className={`caseList__item ${(index + 1) % 2 === 0 ? 'even-item' : ''}`} key={item.Name}>
                    <div className='caseList__item-category'>
                        <div className='caseList__item-heading'>
                            <h2 className='caseList__item-title'>{item.Name}</h2>
                            <p className='caseList__item-caption'>{item.Description}</p>
                        </div>
                        {
                            item.Image?.data &&                        
                            <div className='caseList__item__cutaway desctop-tablet'>
                                <p>{item.Label}</p>
                                {
                                    isMobile ?
                                        <a href={`${item.link}`} className='caseList__item__qr-code'></a> :
                                        <img src={`${backendUrl}${item.Image.data.attributes.url}`} alt='d' className='caseList__item__qr-code'></img>
                                }
                            </div>
                        }
                    </div>
                    <div className='caseList__item-case'>
                        <CaseCard
                            bgColor={'#E9E9E9'}
                            cardData={item?.project?.data}
                            backendUrl={backendUrl}
                        />
                        {
                            item.Image?.data && 
                            <div className='caseList__item__cutaway mobile'>
                                <p>{item.Label}</p>
                                <a href={`${item.link}`} className='caseList__item__qr-code'></a>
                            </div>
                        }
                    </div>
                </article>
            )
        }
    </section>
  )
}

export default CaseList