import React from 'react'
import { Link } from 'react-router-dom'
import './CompetenceHead.css'
import logo from '../../../Media/logo.svg'
import Navigation from '../Navigation/Navigation'

const СompetenceHead = ({ color, data, backendUrl}) => {
  return (
    <section className='competenceHead container'>
        <div className='competenceHead__wrapper'>
            <div className='competenceHead__banner'>
                <div className='competenceHead__banner-top'>
                    <Link to='/'><img src={logo} className='competenceHead__logo' alt='logo'/></Link>
                    <Navigation />
                </div>
                <div className='competenceHead__titles'>
                    <h1 className='competenceHead__title'>{data && data.Name}</h1>
                    <p className='competenceHead__label'>{data && data.Description}</p>
                </div>
            </div>
            <div className='competenceHead__card'>
                <p className='competenceHead__card-label-top'>{data && data.Header.Top}</p>
                <div className='competenceHead__card-icon' style={{background: `url('${backendUrl}${data ? (data.Header.Image.data.attributes.url) : ''}')  center no-repeat ${color}`}}></div>
                <p className='competenceHead__card-label-bottom'>{data && data.Header.Bottom}</p>
            </div>
        </div>
    </section>
  )
}

export default СompetenceHead