import React from 'react'
import { Link } from 'react-router-dom'
import './CaseCard.css'
// import caseCardImg from '../../../Media/CaseCardImg.png'
// import rosatomIcon from '../../../Media/RosatomIcon.svg'

const CaseCard = ({ bgColor, type, cardData, backendUrl }) => {

  return (
    <div className='caseCard' style={{backgroundColor: `${bgColor}`}}>
        {/* <Link to={`/case/${cardData?.id}`} id={cardData?.id}> */}
            <div className='caseCard__head'>
            {cardData?.attributes?.Logo?.data?.attributes?.url && <img src={`${backendUrl}${cardData?.attributes?.Logo?.data?.attributes?.url}`} alt='img' className='caseCard__logo'/>}
                <img src={`${backendUrl}${cardData?.attributes?.Image?.data.attributes.formats.medium.url}`} alt='img' className='caseCard__head-img'/>
            </div>
            <div className='caseCard__body'>
                <div className='caseCard__mid'>
                    <div className='caseCard__name'>
                        <h3>{cardData?.attributes?.Name}</h3>
                        {type !== 'readyMechanics' && <p>{cardData?.attributes?.Year}</p>}
                    </div>
                    {type === 'readyMechanics'
                    ?
                        <div className='caseCard__ready-mechanics'>
                            <p>Готово</p>
                        </div>
                    :
                    <>                    
                        {(cardData?.attributes?.Score) &&
                            <div className='caseCard__rating'>
                                <p>{cardData?.attributes?.Score}</p>
                                <div className='caseCard__rating-star'></div>
                            </div>
                        }
                    </>

                    }
                </div>
                <p className='caseCard__description'>{cardData?.attributes?.Task}</p>
            </div>
        {/* </Link> */}
    </div>
  )
}

export default CaseCard