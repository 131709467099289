import React, { useEffect, useState } from 'react'
import ContactBlock from './ContactBlock/ContactBlock'
import LineUp from './LineUp/LineUp'
import './MainPage.css'
import Strengths from './Strengths/Strengths'
import TopBlock from './TopBlock/TopBlock'
// import strengthsData from '../../TestData/strengthsData.js'
import api from '../../Utils/Api'


const MainPage = ({isMobile}) => {

  const [strengthsData, setStrengthsData] = useState(undefined)
  const [contacts, setContacts] = useState(undefined)
  const [partners, setPartners] = useState(undefined)
  const [slider, setSlider] = useState(undefined)


  useEffect (() => {
    Promise.all(
      [api.getMainPage()])
      .then(([data]) => {
        setStrengthsData(data.data.attributes.Skills)
        setContacts(data.data.attributes.Contacts)
        setPartners(data.data.attributes.Partners.data)
        setSlider(data.data.attributes.Banner)
      }
    )
    .catch((e) => console.error(e));
  }, [])


  return (
    <>
        <TopBlock isMobile={isMobile} partnersData={partners} backendUrl={api._address} sliderData={slider}/>
        <LineUp />
        <Strengths stData={strengthsData} title='Наши сильные стороны' backendUrl={api._address} />
        <ContactBlock isMobile={isMobile} contacts={contacts} backendUrl={api._address} />
    </>
  )
}

export default MainPage