import React, { useEffect, useState } from 'react'
import ContactsHead from './ContactsHead/ContactsHead'
import ContactsWriteUs from './ContactsWriteUs/ContactsWriteUs'
import api from '../../Utils/Api'

const Contacts = ({ isMobile }) => {

  const [contacts, setContacts] = useState(undefined)
  const [contactsQr, setContactsQr] = useState(undefined)

  useEffect (() => {
    Promise.all(
      [api.getContacts()])
      .then(([data]) => {
        setContacts(data.data.attributes.Contacts)
        setContactsQr(data.data.attributes.Contacts.vCard.data.attributes)
      }
    )
    .catch((e) => console.error(e));
  }, [])

  return (
    <>
        <ContactsHead isMobile={isMobile} qrCode={contactsQr} backendUrl={api._address} />
        <ContactsWriteUs data={contacts} />
    </>
  )
}

export default Contacts