import React from 'react'
import './LineUp.css'

const LineUp = () => {
  return (
    <section className='lineUp container'>
            <div className='lineUp__wrapper'>
                <h2 className='lineUp__title'>Состав нашей команды</h2>
                <div className='lineUp__diagram'></div>
            </div>
    </section>
  )
}

export default LineUp