import React from 'react'
import './Footer.css'

const Footer = ({isMobile}) => {
  return (
    <footer className='footer container'>
        <div className='footer__right'>
            <p>©Pattern Digital. All rights reserved</p>
        </div>
        {!isMobile &&
          <div className='footer__links'>
              <a href='#' className='footer__link'>AppStore</a>
              <a href='#' className='footer__link'>Google Play</a>
              <a href='#' className='footer__link'>Наша презентация</a>
              {/* <a href='#' className='footer__soc-netw telegram'></a>
              <a href='#' className='footer__soc-netw youtube'></a> */}
          </div>
        }
    </footer>
  )
}

export default Footer