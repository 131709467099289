// const mainUrl = process.env.REACT_APP_API_URL
// const backendUrl = 'http://23.105.231.110/backend/'

class Api {
    constructor(options) {
        this._address = options.baseUrl;
        this._headers = options.headers;
    }
    
    _checkResponse(res) {
        if (res.ok) {
            return res.json();
        }
        return Promise.reject(`${res.status}`);
    }

    getMainPage() {
        return fetch(`${this._address}/api/main-page?populate[0]=Contacts.vCard&populate[1]=Partners&populate[2]=Skills.Employee&populate[3]=Skills.Image&populate[4]=Banner.Background&populate[5]=Banner.Icon`, {
            headers: this._headers,
            method: "GET",
        
        }).then(this._checkResponse)
    }

    getBusinessPage() {
        return fetch(`${this._address}/api/business-page?populate[0]=Competence.competence.Header.Image&populate[1]=Reviews.Employee&populate[2]=Reviews.Image`, {
            headers: this._headers,
            method: "GET",
        
        }).then(this._checkResponse)
    }

    getInteractivePage() {
        return fetch(`${this._address}/api/interactive-page?populate[0]=Competence.competence.Header.Image&populate[1]=Item.Image&populate[2]=Item.project.Image&populate[3]=Item.project.Logo&populate[4]=mechanics.Image&populate[5]=mechanics.Logo`, {
            headers: this._headers,
            method: "GET",
        
        }).then(this._checkResponse)
    }

    getOnlinePage() {
        return fetch(`${this._address}/api/xr-page?populate[0]=Competence.competence.Header.Image&populate[1]=Item.Image&populate[2]=Item.project.Image&populate[3]=Item.project.Logo`, {
            headers: this._headers,
            method: "GET",
        
        }).then(this._checkResponse)
    }

    getContacts() {
        return fetch(`${this._address}/api/main-page?populate[0]=Contacts.vCard`, {
            headers: this._headers,
            method: "GET",
        }).then(this._checkResponse)
    }

    getVisitingCard() {
        return fetch(`${this._address}/api/main-page?populate[0]=Contacts`, {
            headers: this._headers,
            method: "GET",
        }).then(this._checkResponse)
    }

    getCasePage(id) {
        return fetch(`${this._address}/api/projects/${id}?populate[0]=Image&populate[1]=Number&populate[2]=tools&populate[3]=Promo.Media`, {
            headers: this._headers,
            method: "GET",
        }).then(this._checkResponse)
    }

    sendFeedback(data) {
        console.log('api', data)
        return fetch(`${this._address}/api/feedbacks`, {
            method: 'POST',
            headers: this._headers,
            body: JSON.stringify({
                data: {
                    Name: data.name,
                    Phone: data.phone,
                    Email: data.email,
                    Text: data.message,
                }
            }),
        }).then(this._checkResponse)
    }

}

const api = new Api({
    // baseUrl: `${mainUrl}/backend/api`,
    baseUrl: `https://patterndigital.ru/backend`,
    headers: {
      'Accept': '*/*',
      'Content-Type': 'application/json',
    },
})

export default api