import React, { useState, useEffect } from 'react'
import CompetenceHead from '../Common/СompetenceHead/СompetenceHead'
// import InteractiveIcon from '../../Media/IconInteractiveBlack.svg'
import CaseList from '../Common/CaseList/CaseList'
import ReadyMechanics from '../Common/ReadyMechanics/ReadyMechanics'
import api from '../../Utils/Api'

const Interactive = ({ isMobile }) => {

  const [headerData, setHeaderData] = useState(undefined)
  const [caseListData, setCaseListData] = useState(undefined)
  const [readyMechanics, setReadyMechanics] = useState(undefined)

  // const [allData, setAllData] = useState(undefined)

  // useEffect (() => {
  //   Promise.all(
  //     [api.getInteractivePage()])
  //     .then(([data]) => {
  //       setHeaderData(data.data.attributes.Competence.competence.data.attributes)
  //       setCaseListData(data.data.attributes.Item)
  //     }
  //     )
  //     .catch((e) => console.error(e));
  // }, [])

  useEffect(() => {
    api.getInteractivePage()
    .then(data => {
      setHeaderData(data.data.attributes.Competence.competence.data.attributes)
      setCaseListData(data.data.attributes.Item)
      setReadyMechanics(data.data.attributes.mechanics.data)
    })
    .catch((e) => console.error(e))
  }, [])

  return (
    <>
        <CompetenceHead
            data={headerData}
            color={'#CEEB1A'}
            isMobile={isMobile}
            backendUrl={api._address}
        />
        <CaseList isMobile={isMobile} caseData={caseListData} backendUrl={api._address} />
        <ReadyMechanics readyMechanicsData={readyMechanics} backendUrl={api._address} />
    </>
  )
}

export default Interactive