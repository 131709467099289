import React from 'react'
import './Strengths.css'

const Strengths = ({ stData, isReview, backendUrl, title, description }) => {

  return (
    <section className='strengths container'>
            <div className='strengths__header'>
                <h2 className='strengths__header-title'>{title}</h2>
                <p className='strengths__header-text' style={{color: `${isReview ? '#BDBDBD' : ''}`}}>{description}</p>
            </div>
            <div className='strengths__list'>
                {stData?.map((item, i) => 
                    <div className={`strength__card ${isReview ? 'clientsReview' : ''}`} key={i}>
                        <div className='strength__card-head'>
                            <div className='strength__card-icon'
                                style={{background: `url('${backendUrl}${item.Image.data.attributes.url}') center no-repeat`,
                                backgroundColor: '#E9E9E9',
                                backgroundSize: `${isReview ? '40px' : '24px'}`}}>
                            </div>
                            <h3>{item.Name}</h3>
                            {isReview && <p className='strength__card-text'>{item.Description}</p>}
                        </div>
                        {!isReview && <p className='strength__card-text'>{item.Description}</p>}
                        {
                            isReview && 
                            <div className='strength__card-client'>                                
                                <h4>{item.Employee.Name}</h4>
                                <p>{item.Employee.Position}</p>
                            </div>
                        }
                    </div>
                )}
            </div>
    </section>
  )
}

export default Strengths