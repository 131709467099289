import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import './Navigation.css'

const Navigation = ({ isWhite }) => {

  const [isSubmenuActive, setIsSubmenuActive] = useState(false)

  return (
    <>
    
        <nav className='navigation'>
            <ul className={`navigation__list ${isWhite ? 'nav-white' : ''}`}>
                <li className='navigation__list-item'><Link to='/'>Главная</Link></li>
                <li className='navigation__list-item'><Link to='/contacts'>Контакты</Link></li>
                <li className='navigation__list-item'>
                    <div className='drop-down-item' onClick={() => setIsSubmenuActive(!isSubmenuActive)}>
                        Услуги
                        <div className={`drop-down-arrow ${isWhite ? 'arrow-white' : ''} ${isSubmenuActive ? 'arrow-acrive' : ''}`}></div>
                    </div>
                    {isSubmenuActive && 
                        <ul className={`navigation__submenu ${isWhite ? 'nav-white' : ''}`}>
                            <li className='submenu__item'><Link to='/interactive'>Interactive</Link></li>
                            <li className='submenu__item'><Link to='/business'>Business</Link></li>
                            {/* <li className='submenu__item'><Link to='/xr'>XR</Link></li> */}
                            <li className='submenu__item'><Link to='/online'>Online</Link></li>
                        </ul>
                    }
                </li>
            </ul>
        </nav>

    </>
  )
}

export default Navigation