import React, {useEffect, useRef, useState} from 'react'
import './VisitingCard.css'
import logo from '../../Media/logoWhite.svg'
import api from '../../Utils/Api'
import * as htmlToImage from 'html-to-image';

const VisitingCard = () => {

  const [contacts, setContacts] = useState(undefined)

  const visitingCard = useRef(null)

  const downloadVisitingCard = async () => {
    const dataUrl = await htmlToImage.toPng(visitingCard.current);
    const link = document.createElement('a');
    link.download = "Pattern-visiting-card.png";
    link.href = dataUrl;
    link.click();
  }


  useEffect(() => {
    api.getVisitingCard()
    .then(data => {
      setContacts(data?.data?.attributes?.Contacts)
    })
    .catch((e) => console.error(e))
  }, [])


  return (
    <div className='visitingCard__container'>
        <h1>Визитка</h1>
        <div className='visitingCard__content'>
            <div className='visitingCard' ref={visitingCard}>
              <img src={logo} alt='Pattern logo' className='visitingCard__logo'/>
              <div className='visitingCard__contacts'>
                <span>{contacts?.Phone}</span>
                <span style={{textDecoration: 'underline'}}>{contacts?.Email}</span>
              </div>
            </div>
            <div className='visitingCard__btn' onClick={downloadVisitingCard}>Сохранить в телефон</div>
        </div>
    </div>
  )
}

export default VisitingCard