import React, { useState, useEffect } from 'react'
import CompetenceHead from '../Common/СompetenceHead/СompetenceHead'
// import OnlineIcon from '../../Media/IconOnlineBlack.svg'
import WriteUs from '../Common/WriteUs/WriteUs'
import CaseList from '../Common/CaseList/CaseList'
import api from '../../Utils/Api'
// import Advantages from '../Common/Advantages/Advantages'
// import DirectionsList from '../Common/DirectionsList/DirectionsList'

const Online = ({ isMobile }) => {

  const [headerData, setHeaderData] = useState(undefined)
  const [caseListData, setCaseListData] = useState(undefined)

  useEffect(() => {
    api.getOnlinePage()
    .then(data => {
      setHeaderData(data.data.attributes.Competence.competence.data.attributes)
      setCaseListData(data.data.attributes.Item)
    })
    .catch((e) => console.error(e))
  }, [])

  return (
    <>
        <CompetenceHead
            data={headerData}
            color={'#A966FF'}
            isMobile={isMobile}
            backendUrl={api._address}
        />
        <CaseList isMobile={isMobile} caseData={caseListData} backendUrl={api._address} />
        {/* <Advantages /> */}
        {/* <DirectionsList isMobile={isMobile}/> */}
        <WriteUs isMobile={isMobile} />
    </>
  )
}

export default Online