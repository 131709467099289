import React from 'react'
import { Link } from 'react-router-dom'
import FeedbackForm from '../../Common/FeedbackForm/FeedbackForm'
import './ContactBlock.css'

const ContactBlock = ({ isMobile, contacts, backendUrl }) => {

  return (
    <section className='contactBlock container'>
        <div className='contactBlock__wrapper'>
            <div className='contactBlock__card'>
                <div className='contactBlock__heading'>
                    <h2 className='contactBlock__title'>Контакты</h2>
                    <p className='contactBlock__text'>
                        Получите предварительную калькуляцию вашего проекта в течении 12 часов:
                    </p>
                </div>
                <div className='contactBlock__inf'>
                    {
                        contacts &&

                        <div className='contactBlock__contacts'>
                            <a href='tel: +79099498853' className='contactBlock__phone'>{contacts.Phone}</a>
                            <a href='mailto: pro@patterndigital.com' className='contactBlock__email'>{contacts.Email}</a>
                        </div>
                    }
                    <div className='contactBlock__cutaway'>
                        {
                            isMobile ?
                            <>
                                <p>Наша визитка</p>
                                <Link to='/visitingcard' className='contactBlock__qr-code'></Link>
                            
                            </> :
                            <>                            
                                <p>Элетронная визитка<br/>Pattern digital</p>
                                {contacts && <img src={`${backendUrl}${contacts.vCard.data.attributes.url}`} alt={contacts.vCard.data.attributes.alternativeText} className='contactBlock__qr-code'></img>}
                            </>
                        }
                    </div>
                </div>
            </div>
            <FeedbackForm />
        </div>
    </section>
  )
}

export default ContactBlock