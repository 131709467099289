import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import './TopBlock.css'
import logo from '../../../Media/logo.svg'
import Navigation from '../../Common/Navigation/Navigation'

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-creative";
import "swiper/css/pagination";
import { EffectCreative, Pagination, Autoplay } from "swiper";


const TopBlock = ({ isMobile, partnersData, sliderData, backendUrl }) => {

  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '"></span>';
    },
  };

  const [partnersTicker, setPartnersTicker] = useState(false)

  useEffect(() => {
    const tickerTimeout = setTimeout(() => setPartnersTicker(true), 500);
    return () => clearTimeout(tickerTimeout);
  }, []);
  

  return (
    <section className='topBlock container'>
                <div className='topBlock__wrapper'>
                    <div className='topBlock__banner'>
                        <div className='topBlock__banner-left'>
                            {!isMobile && <Link to='/'><img src={logo} className='topBlock__logo' alt='logo'/></Link>}
                            <div className='topBlock__titles'>
                                <h1 className='topBlock__title'>IT-спецназ</h1>
                                <p className='topBlock__label'>Решаем задачи вашего бизнеса<br/>в цифровой среде</p>
                            </div>
                        </div>
                        <div className='topBlock__banner-right'>
                            <div className='topBlock__nav-wrapper'>
                                {!isMobile && <Navigation />}
                            </div>
                            <div className='topBlock__stamp'></div>
                        </div>
                    </div>


                    <div className='topBlock__partners'>
                        <h2 className='topBlock__partners-title'>Партнеры</h2>
                        <div className='topBlock__ticker'>
                                {
                                    partnersData &&
                                        <>
                                            <div className={`topBlock__ticker-items ${partnersTicker ? 'marquee' : ''}`}>
                                                {partnersData?.map((i, index) => <img src={`${backendUrl}${i.attributes.url}`} alt={`${i.attributes.alternativeText}`} key={index} className="topBlock__ticker-item"/>)}
                                            </div>
                                            <div aria-hidden="true" className={`topBlock__ticker-items ${partnersTicker ? 'marquee' : ''}`}>
                                                {partnersData?.map((i, index) => <img src={`${backendUrl}${i.attributes.url}`} alt={`${i.attributes.alternativeText}`} key={index} className="topBlock__ticker-item"/>)}
                                            </div>
                                        </>
                                }
                        </div>
                        <p className='topBlock__partners-label'>Нашими услугами пользуются</p>
                    </div>

                    <div className='topBlock__slider'>
                        <h2 className='topBlock__slider-title'>Наши компетенции</h2>
                        {
                            sliderData &&

                                <Swiper
                                grabCursor={true}
                                loop={true}
                                effect={"creative"}
                                creativeEffect={{
                                prev: {
                                    shadow: true,
                                    translate: ["-120%", 0, -500],
                                },
                                next: {
                                    shadow: true,
                                    translate: ["120%", 0, -500],
                                },
                                }}
                                autoplay={{
                                    delay: 4000,
                                    disableOnInteraction: false,
                                }}
                                pagination={pagination}
                                modules={[EffectCreative, Pagination, Autoplay]}
                                className="mySwiper2"
                            >
                                {sliderData?.map((item) => 
                                
                                    <SwiperSlide key={item?.CaseCount}>
                                        <div className='topBlock__slide' style={{background: `url(${backendUrl}${item?.Background?.data?.attributes?.url}) center no-repeat`, backgroundSize: 'cover'}}>
                                            <div className='topBlock__slide__card'>
                                                <div className='topBlock__slide__card-icon' style={{background: `url(${backendUrl}${item?.Icon?.data?.attributes?.url}) center no-repeat`, backgroundSize: '20px 20px', backgroundColor: '#141414'}}></div>
                                                <div className='topBlock__slide__card-title'>
                                                    <h3>{item?.Name}</h3>
                                                    <p>{item?.Description}</p>
                                                </div>
                                                <div className='topBlock__slide__cart-case'>
                                                    <span className='topBlock__slide__card-case__count'>{item?.CaseCount}</span>
                                                    <span className='topBlock__slide__card-case__name'>кейсов</span>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                
                                )}
                            </Swiper>
                        }
                    </div>
                </div>
        </section>
  )
}

export default TopBlock